import month1 from "./times/1.json";
import month2 from "./times/2.json";
import month3 from "./times/3.json";
import month4 from "./times/4.json";
import month5 from "./times/5.json";
import month6 from "./times/6.json";
import month7 from "./times/7.json";
import month8 from "./times/8.json";
import month9 from "./times/9.json";
import month10 from "./times/10.json";
import month11 from "./times/11.json";
import month12 from "./times/12.json";
import {Day, Month, MonthWithDays, PrayerTimes} from "./prayerTime";

export const readTimesForMonth = (month: Month): Day[] => {
    switch (month) {
        case Month.January:
            return month1;
        case Month.February:
            return month2;
        case Month.March:
            return month3;
        case Month.April:
            return month4;
        case Month.May:
            return month5;
        case Month.June:
            return month6;
        case Month.July:
            return month7;
        case Month.August:
            return month8;
        case Month.September:
            return month9;
        case Month.October:
            return month10;
        case Month.November:
            return month11;
        case Month.December:
            return month12;
    }
};

export const getAllMonths = (): MonthWithDays[] => {
    return Object.keys(Month)
        .filter(item => isNaN(Number(item)))
        .map(m => m as Month)
        .map(month => ({month: month, days: readTimesForMonth(month)}));
};

export const currentMonthNumber = (): number => (new Date()).getMonth();

export const currentDayNumber = (): number => (new Date()).getDate();

export const getCurrentDayFromMonth = (month: MonthWithDays): PrayerTimes => {
    return month.days.find(d => d.date === currentDayNumber())!.times;
};
