import React, {useState} from "react";
import {Tab, Tabs, ThemeProvider} from "@mui/material";
import theme from "./theme";
import {TodayPage} from "./TodayPage";
import {MonthPage} from "./MonthPage";
import {getAllMonths} from "./prayerTimeRepo";
import {MonthWithDays} from "./prayerTime";

const Pages = Object.freeze({
    Today: 0,
    Month: 1
});

function App() {
    const [selectedTab, setSelectedTab] = useState(0);

    const months = getAllMonths();

    return (
        <ThemeProvider theme={theme}>
            <div style={{display: "flex", flexDirection: "column"}} className="main-body">
                <div style={{flex: 1, overflowY: "auto"}}>
                    <Content tab={selectedTab} months={months}/>
                </div>
                <Tabs variant="fullWidth" value={selectedTab} onChange={(e, index) => setSelectedTab(index)}>
                    <Tab label={"Today"}></Tab>
                    <Tab label={"month"}></Tab>
                </Tabs>
            </div>
        </ThemeProvider>
    );
}

const Content = ({tab, months}: { tab: number, months: MonthWithDays[] }) => {
    if (tab === Pages.Today) {
        return <TodayPage months={months}/>;
    } else if (tab === Pages.Month) {
        return <MonthPage months={months}/>;
    } else {
        return <>Error. Unknown page</>;
    }
};

export default App;
