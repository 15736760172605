export interface PrayerTime {
    hour: number;
    minute: number;
}

export const prayerTimeAsString = (prayerTime: PrayerTime): string => {
    const hourString = prayerTime.hour.toString().padStart(2, "0");
    const minuteString = prayerTime.minute.toString().padStart(2, "0");
    return `${hourString}:${minuteString}`;
};

export const minutesFromMidnight = (prayerTime: PrayerTime): number => prayerTime.hour * 60 + prayerTime.minute;

export interface PrayerTimeWithName {
    name: string;
    prayerTime: PrayerTime;
}

export interface PrayerTimes {
    fajr: PrayerTime,
    sunrise: PrayerTime,
    zuhr: PrayerTime,
    asr: PrayerTime,
    maghrib: PrayerTime,
    isha: PrayerTime
}

export const prayerTimesAsList = (prayerTimes: PrayerTimes): PrayerTimeWithName[] => {
    return [
        {name: "Fajr", prayerTime: prayerTimes.fajr},
        {name: "Sunrise", prayerTime: prayerTimes.sunrise},
        {name: "Zuhr", prayerTime: prayerTimes.zuhr},
        {name: "Asr", prayerTime: prayerTimes.asr},
        {name: "Maghrib", prayerTime: prayerTimes.maghrib},
        {name: "Isha", prayerTime: prayerTimes.isha}
    ];
};

export enum Month {
    January = "January",
    February = "February",
    March = "March",
    April = "April",
    May = "May",
    June = "June",
    July = "July",
    August = "August",
    September = "September",
    October = "October",
    November = "November",
    December = "December"
}

export const humanNumberForMonth = (month: Month): number => {
    switch (month) {
        case Month.January:
            return 1;
        case Month.February:
            return 2;
        case Month.March:
            return 3;
        case Month.April:
            return 4;
        case Month.May:
            return 5;
        case Month.June:
            return 6;
        case Month.July:
            return 7;
        case Month.August:
            return 8;
        case Month.September:
            return 9;
        case Month.October:
            return 10;
        case Month.November:
            return 11;
        case Month.December:
            return 12;
    }
};

export interface Day {
    date: number;
    month: string;
    times: PrayerTimes;
}

export interface MonthWithDays {
    month: Month;
    days: Day[];
}

export const timeNow = (): PrayerTime => {
    const date = new Date();
    return {hour: date.getHours(), minute: date.getMinutes()};
};

export const isTimeInFuture = (time: PrayerTime): boolean => {
    return minutesFromMidnight(time) > minutesFromMidnight(timeNow());
};
