import {MonthWithDays, prayerTimeAsString} from "./prayerTime";
import {currentMonthNumber} from "./prayerTimeRepo";
import {styled, Table, TableBody, TableHead, TableRow} from "@mui/material";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {primaryFaded} from "./theme";

export function MonthPage({months}: { months: MonthWithDays[] }) {
    const thisMonth = months[currentMonthNumber()];
    return (
        <div style={{padding: 16}}>
            <TimeTable month={thisMonth}/>
        </div>
    );
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
    td: {
        textAlign: "center"
    }
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: primaryFaded
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0
    }
}));

export const TimeTable = ({month}: { month: MonthWithDays }) => {
    return (
        <Table stickyHeader>
            <TableHead>
                <StyledTableRow>
                    <StyledTableCell>Fajr</StyledTableCell>
                    <StyledTableCell>Sunrise</StyledTableCell>
                    <StyledTableCell>Zuhr</StyledTableCell>
                    <StyledTableCell>Asr</StyledTableCell>
                    <StyledTableCell>Maghrib</StyledTableCell>
                    <StyledTableCell>Isha</StyledTableCell>
                </StyledTableRow>
            </TableHead>
            <TableBody>
                {month.days.map(day => {
                    return (
                        <StyledTableRow>
                            <td>{prayerTimeAsString(day.times.fajr)}</td>
                            <td>{prayerTimeAsString(day.times.sunrise)}</td>
                            <td>{prayerTimeAsString(day.times.zuhr)}</td>
                            <td>{prayerTimeAsString(day.times.asr)}</td>
                            <td>{prayerTimeAsString(day.times.maghrib)}</td>
                            <td>{prayerTimeAsString(day.times.isha)}</td>
                        </StyledTableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
