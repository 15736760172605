import {createTheme} from "@mui/material/styles";
import green from "@mui/material/colors/green";

const theme = createTheme({
    palette: {
        primary: green
    }
});

export const primary = green["500"];
export const primaryFaded = green["100"];

export default theme;
