import {currentMonthNumber, getCurrentDayFromMonth} from "./prayerTimeRepo";
import {isTimeInFuture, MonthWithDays, prayerTimeAsString, prayerTimesAsList, PrayerTimeWithName} from "./prayerTime";
import {primary} from "./theme";

const TimeList = ({times}: { times: PrayerTimeWithName[] }) => {
    return (
        <div style={{display: "flex", flexDirection: "column", gap: 16}}>
            {times.map(time => {
                const isFuture = isTimeInFuture(time.prayerTime);
                const colour = isFuture ? primary : "#888";
                return (
                    <div style={{border: "2px solid " + colour, padding: 8, display: "flex", borderRadius: 16, color: colour}}>
                        {time.name}
                        <div style={{flex: 1}}/>
                        {prayerTimeAsString(time.prayerTime)}
                    </div>
                );
            })}
        </div>
    );
};

export function TodayPage({months}: { months: MonthWithDays[] }) {
    const thisMonth = months[currentMonthNumber()];
    const today = getCurrentDayFromMonth(thisMonth);
    const times = prayerTimesAsList(today);
    return (
        <div style={{padding: 16}}>
            <TimeList times={times}/>
        </div>
    );
}
